<template>
  <div class="mb-4">
    <div>
      <b-row v-if="contract_id">
        <b-col md="12">
          <h5>
            <b-badge variant="light-success"
              >Contract Unique ID :
              {{ contract_id ? "#CA" + contract_id : "" }}</b-badge
            >
          </h5>
        </b-col>
      </b-row>

      <b-row
        cols="12"
        class="d-flex justify-content-start px-1"
        v-if="contract_id"
      >
        <b-button
          :variant="
            cat.key === currentContractCategory ? 'primary' : 'outline-primary'
          "
          v-for="(cat, index) in contractCategories"
          :key="'section_' + index"
          class="mr-1 mt-1"
          @click="setContractCategory(cat.key)"
          >{{ cat.label }}</b-button
        >
      </b-row>
      <div v-if="currentContractCategory === 'preview'">
        <contract-details />
      </div>
      <form-wizard
        v-else
        ref="wizard"
        color="#1f307a"
        :title="null"
        :subtitle="null"
        shape="tab"
        finish-button-text="Save"
        back-button-text="Previous"
        next-button-text="Next"
        @on-complete="
          () => {
            showModal('Are you sure want to submit?', 'save').then((value) => {
              if (value) formSubmitted();
            });
          }
        "
        @on-change="getTabIndex"
      >
        <!-- Contract Details Tab -->

        <tab-content
          title="Contract Details"
          :before-change="validationContractDetails"
        >
          <b-row
            cols="12"
            class="d-flex justify-content-start py-1 px-1"
            v-if="contract_id"
          >
            <b-button
              size="sm"
              :variant="
                section.key === currentContractSection
                  ? 'success'
                  : 'outline-success'
              "
              v-for="(section, index) in getSelectedContractOptions()"
              :key="'section_' + index"
              class="mr-1 mt-1"
              @click="setContractSection(section.key)"
              >{{ section.label }}</b-button
            >
          </b-row>
          <validation-observer ref="contractDetails" tag="form">
            <b-card
              border-variant="primary"
              v-if="currentContractSection === 'all'"
            >
              <b-card-text>
                <b-row>
                  <b-col col-md="4" sm="4">
                    <b
                      ><b-badge pill variant="warning">
                        Is this contract is a sub-contract?</b-badge
                      >
                      <b-form-checkbox
                        switch
                        inline
                        v-model="form.sub_contract"
                        @input="getContractsSelectList"
                        ><b>{{
                          form.sub_contract == false ? "No" : "Yes"
                        }}</b></b-form-checkbox
                      ></b
                    >
                  </b-col>

                  <b-col v-show="form.sub_contract" col-md="8" sm="8">
                    <b-form-group label="Main Contract">
                      <v-select
                        id="main-contract-v-select"
                        placeholder="Select Main Contract"
                        label="name"
                        :options="contracts_select_list"
                        :reduce="(c) => c.contract_id"
                        v-model="form.main_contract_id"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr class="m-1" />

                <b-row class="mt-1">
                  <b-col col-md="4" sm="4">
                    <b-form-group label="Nature of Contract">
                      <validation-provider
                        #default="{ errors }"
                        name="Nature of Contract"
                        rules="required"
                      >
                        <v-select
                          :disabled="contract_id && !is_copy"
                          placeholder="Nature of Contract"
                          id="nature-of-contract"
                          :options="contract_natures"
                          :close-on-select="true"
                          v-model="form.contract.contract_nature"
                          @input="
                            (i) => {
                              onContractNatureChange(i);
                              getAcquiredTrl();
                            }
                          "
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col col-md="4" sm="4">
                    <b-form-group label="Movie">
                      <validation-provider
                        #default="{ errors }"
                        name="Movie"
                        rules="required"
                      >
                        <v-select
                          placeholder="Movie"
                          id="movie_id"
                          label="name"
                          :options="$store.state.master.movies"
                          :reduce="(movie) => movie.id"
                          :close-on-select="false"
                          @input="
                            (i) => {
                              getAcquiredTrl();
                            }
                          "
                          v-model="form.contract.movie_id"
                          multiple
                        >
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col col-md="4" sm="4">
                    <b-form-group :label="form_dynamics.vendor_label">
                      <validation-provider
                        #default="{ errors }"
                        :name="form_dynamics.vendor_label"
                        rules="required"
                      >
                        <v-select
                          :disabled="contract_id && !is_copy"
                          placeholder="Licensor/Licensee"
                          id="vendor"
                          label="company"
                          :options="$store.state.master.vendors"
                          :reduce="(vendor) => vendor.vendor_id"
                          :close-on-select="true"
                          v-model="form.contract.vendor_id"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col col-md="4" sm="4">
                    <b-form-group label="Type of Contract">
                      <validation-provider
                        #default="{ errors }"
                        name="Type of Contract"
                        rules="required"
                      >
                        <v-select
                          placeholder="Type of Contract"
                          id="contract-type"
                          label="lbl_name"
                          :options="$store.state.master.contract_type"
                          :reduce="(lbl) => lbl.lbl_id"
                          :close-on-select="true"
                          v-model="form.contract.contract_type"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col col-md="4" sm="4">
                    <b-form-group label="Offer Date">
                      <flat-pickr
                        :config="{
                          ...$flatPickrConfig,
                        }"
                        placeholder="Offer Date"
                        class="form-control"
                        v-model="form.contract.offer_date"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col col-md="4" sm="4">
                    <b-form-group label="Contract Date">
                      <validation-provider
                        #default="{ errors }"
                        name="Contract Date"
                        rules="required"
                      >
                        <flat-pickr
                          :config="{
                            ...$flatPickrConfig,
                          }"
                          placeholder="Contract Date"
                          class="form-control"
                          v-model="form.contract.contract_date"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col col-md="12" sm="12">
                    <b-form-group label="Comment 1">
                      <b-form-textarea
                        placeholder="Comment 1"
                        v-model="form.contract.contract_comment"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col col-md="4" sm="4">
                    <b-form-group label="License Begin">
                      <validation-provider
                        #default="{ errors }"
                        name="License Begin"
                        rules="required"
                      >
                        <flat-pickr
                          placeholder="License Begin"
                          class="form-control"
                          v-model="form.contract.contract_begin"
                          :config="{
                            maxDate: form.contract.contract_end,
                            minDate: form_dynamics.min_date,
                            ...$flatPickrConfig,
                          }"
                          :disabled="movieSelected"
                          @input="
                            () => {
                              getAcquiredTrl();
                            }
                          "
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col col-md="4" sm="4">
                    <b-form-group label="License End">
                      <validation-provider
                        #default="{ errors }"
                        name="License End"
                        rules="required"
                      >
                        <flat-pickr
                          placeholder="License End"
                          class="form-control"
                          v-model="form.contract.contract_end"
                          :config="{
                            minDate: form.contract.contract_begin,
                            maxDate: form_dynamics.max_date,
                            ...$flatPickrConfig,
                          }"
                          :disabled="movieSelected"
                          @input="
                            () => {
                              getAcquiredTrl();
                            }
                          "
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col col-md="4" sm="4">
                    <b-form-group label="Effective Date">
                      <flat-pickr
                        :config="{
                          ...$flatPickrConfig,
                        }"
                        placeholder="Effective Date"
                        class="form-control"
                        v-model="form.contract.effective_date"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col col-md="4" sm="4">
                    <b-form-group label="Duration (Years)">
                      <b-form-input
                        type="number"
                        placeholder="Duration (Years)"
                        v-model="form.contract.duration_year"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col col-md="4" sm="4">
                    <b-form-group label="Duration (Months)">
                      <b-form-input
                        type="number"
                        placeholder="Duration (Months)"
                        v-model="form.contract.duration_month"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col col-md="4" sm="4">
                    <b-form-group label="NOD">
                      <flat-pickr
                        :config="{
                          ...$flatPickrConfig,
                        }"
                        placeholder="NOD"
                        class="form-control"
                        v-model="form.contract.nod"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col col-md="4" sm="4">
                    <b-form-group label="Sell-Off Period">
                      <b-form-input
                        placeholder="Sell-Off Period"
                        v-model="form.contract.sellofperiod"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col col-md="4" sm="4">
                    <b-form-group label="Number of Runs (TV)">
                      <b-form-input
                        placeholder="Number of Runs (TV)"
                        v-model="form.contract.number_of_run"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col col-md="4" sm="4">
                    <b-form-group label="Material Delivery">
                      <b-form-input
                        placeholder="Material Delivery"
                        v-model="form.contract.material_delivery"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col col-md="12" sm="12">
                    <b-form-group label="Comment 2">
                      <b-form-textarea
                        placeholder="Comment 2"
                        v-model="form.contract.duration_comment"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card>
            <b-card
              border-variant="primary"
              v-if="currentContractSection === 'all'"
              ><b-card-text>
                <b-row>
                  <b-col col-md="3" sm="3">
                    <b
                      ><b-badge pill variant="warning"
                        >Extension Clause?</b-badge
                      ></b
                    >
                    <b-form-checkbox
                      switch
                      inline
                      v-model="form.contract.extension_clause"
                      >{{
                        form.contract.extension_clause == false ? "No" : "Yes"
                      }}</b-form-checkbox
                    >
                  </b-col>

                  <b-col
                    v-if="form.contract.extension_clause"
                    col-md="4"
                    sm="4"
                  >
                    <b-form-group label="Extension Date">
                      <flat-pickr
                        :config="{
                          ...$flatPickrConfig,
                        }"
                        placeholder="Extension Date"
                        class="form-control"
                        v-model="form.contract.extension_date"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="form.contract.extension_clause"
                    col-md="5"
                    sm="5"
                  >
                    <b-form-group label="Extension Comment">
                      <b-form-textarea
                        placeholder="Extension Comment"
                        v-model="form.contract.extension_clause_desc"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card>
            <b-card
              border-variant="primary"
              v-if="currentContractSection === 'all'"
              ><b-card-text>
                <b-row>
                  <b-col col-md="6" sm="6">
                    <b-form-group label="Licensed Languages">
                      <v-select
                        placeholder="Licensed Languages"
                        label="lbl_name"
                        :options="$store.state.master.language"
                        :reduce="(label) => label.lbl_id"
                        multiple
                        :close-on-select="false"
                        v-model="form.contract.languages"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col col-md="6" sm="6">
                    <b-form-group label="Licensed Subtitles">
                      <v-select
                        placeholder="Licensed Subtitles"
                        label="lbl_name"
                        :options="$store.state.master.language"
                        :reduce="(label) => label.lbl_id"
                        multiple
                        :close-on-select="false"
                        v-model="form.contract.subtitles"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- Acquired Trl -->
                <b-row
                  v-if="
                    form.contract.contract_nature === 'Acquisition' &&
                    acquiredTrls &&
                    acquiredTrls.length
                  "
                  class="mt-2"
                >
                  <b-col col-md="12" sm="12">
                    <h6>
                      <b-badge variant="light-primary">
                        Already acquired Rights, Territory &amp; Language for
                        selected date range</b-badge
                      >
                    </h6>
                    <table id="contractTablesStyle">
                      <tr>
                        <th style="width: 20%">Rights</th>
                        <th style="width: 20%">Territory</th>
                        <th style="width: 20%">Language</th>
                        <th></th>
                      </tr>
                      <tr
                        v-for="(trls, trls_index) in acquiredTrls"
                        :key="'trls_' + trls_index"
                      >
                        <td>
                          <treeselect
                            :options="trl_rights"
                            multiple
                            placeholder="Rights"
                            v-model="trls.right"
                            :disabled="true"
                          />
                        </td>
                        <td>
                          <treeselect
                            allowSelectingDisabledDescendants
                            :options="trl_territories"
                            multiple
                            placeholder="Territory"
                            v-model="trls.territory"
                            :disabled="true"
                          />
                        </td>
                        <td>
                          <v-select
                            placeholder="Language"
                            label="lbl_name"
                            :options="trl_languages"
                            :reduce="(label) => label.lbl_id"
                            multiple
                            v-model="trls.language"
                            :disabled="true"
                          />
                        </td>
                      </tr>
                    </table>
                  </b-col>
                </b-row>

                <b-row
                  v-if="
                    form.contract.contract_nature === 'Sales' &&
                    availableTrls &&
                    availableTrls.length
                  "
                  class="mt-2"
                >
                  <b-col col-md="12" sm="12">
                    <h6>
                      <b-badge variant="light-primary">
                        Available Rights, Territory &amp; Language for selected
                        date range</b-badge
                      >
                    </h6>
                    <table id="contractTablesStyle">
                      <tr>
                        <th style="width: 20%">Rights</th>
                        <th style="width: 20%">Territory</th>
                        <th style="width: 20%">Language</th>
                        <th></th>
                      </tr>
                      <tr
                        v-for="(trls, trls_index) in availableTrls"
                        :key="'trls_' + trls_index"
                      >
                        <td>
                          <treeselect
                            :options="trl_rights"
                            multiple
                            placeholder="Rights"
                            v-model="trls.right"
                            :disabled="true"
                          />
                        </td>
                        <td>
                          <treeselect
                            allowSelectingDisabledDescendants
                            :options="trl_territories"
                            multiple
                            placeholder="Territory"
                            v-model="trls.territory"
                            :disabled="true"
                          />
                        </td>
                        <td>
                          <v-select
                            placeholder="Language"
                            label="lbl_name"
                            :options="trl_languages"
                            :reduce="(label) => label.lbl_id"
                            multiple
                            v-model="trls.language"
                            :disabled="true"
                          />
                        </td>
                      </tr>
                    </table>
                  </b-col>
                </b-row>

                <!-- Trl -->
                <b-row class="mt-2">
                  <b-col col-md="12" sm="12">
                    <h6>
                      <b-badge variant="light-primary"
                        >Rights, Territory, Language</b-badge
                      >
                    </h6>
                    <table id="contractTablesStyle" v-if="trls_options.length">
                      <tr>
                        <th style="width: 20%">Rights</th>
                        <th style="width: 20%">Territory</th>
                        <th style="width: 20%">Language</th>
                        <th style="width: 20%">Subtitles</th>
                        <th>Exclusive</th>
                        <th style="width: 20%">Comment Rights</th>
                        <th></th>
                      </tr>
                      <tr
                        v-for="(trls, trls_index) in form.trls"
                        :key="'trls_' + trls_index"
                      >
                        <!-- {{
                          trls_options
                        }} -->
                        <td>
                          <validation-provider
                            #default="{ errors }"
                            name="Rights"
                            rules="required"
                          >
                            <treeselect
                              :options="trls_options[trls_index].trl_rights"
                              multiple
                              placeholder="Rights"
                              v-model="trls.right"
                              :disabled="licenseBeginSelected"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <!-- {{ trls.territory }} -->
                          <validation-provider
                            #default="{ errors }"
                            name="Territory"
                            rules="required"
                          >
                            <treeselect
                              allowSelectingDisabledDescendants
                              :options="
                                trls_options[trls_index].trl_territories
                              "
                              multiple
                              placeholder="Territory"
                              v-model="trls.territory"
                              :disabled="licenseBeginSelected"
                              @input="onTrlTerritorySelect($event, trls_index)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            #default="{ errors }"
                            name="Language"
                            rules="required"
                          >
                            <v-select
                              placeholder="Language"
                              label="lbl_name"
                              :options="trls_options[trls_index].trl_languages"
                              :reduce="(label) => label.lbl_id"
                              multiple
                              :close-on-select="false"
                              v-model="trls.language"
                              :disabled="licenseBeginSelected"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <v-select
                            placeholder="Subtitles"
                            label="lbl_name"
                            :options="trl_languages"
                            :reduce="(label) => label.lbl_id"
                            multiple
                            :close-on-select="false"
                            v-model="trls.subtitle"
                            :disabled="licenseBeginSelected"
                          />
                        </td>
                        <td>
                          <b-form-checkbox
                            switch
                            inline
                            v-model="trls.exclusive"
                          />
                        </td>
                        <td>
                          <b-form-textarea
                            placeholder="Comment Rights"
                            v-model="trls.comment"
                          />
                        </td>
                        <td>
                          <feather-icon
                            v-if="trls_index != 0"
                            style="cursor: pointer"
                            icon="MinusCircleIcon"
                            size="25"
                            @click="removeTrls"
                          />
                          <feather-icon
                            v-if="trls_index == form.trls.length - 1"
                            style="cursor: pointer"
                            icon="PlusCircleIcon"
                            size="25"
                            @click="addTrls"
                          />
                        </td>
                      </tr>
                    </table>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card>
            <b-card
              border-variant="primary"
              v-if="currentContractSection === 'all'"
              ><b-card-text>
                <b-row class="mt-1">
                  <b-col col-md="3" sm="3">
                    <b-form-group label="P&A Cap">
                      <b-form-input
                        type="number"
                        placeholder="P&A Cap"
                        v-model="form.contract.pacap"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col col-md="3" sm="3">
                    <b-form-group label="P&A Internal">
                      <b-form-input
                        type="number"
                        placeholder="P&A Internal"
                        v-model="form.contract.painternal"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col col-md="3" sm="3">
                    <b-form-group label="P&A Budget Approved">
                      <b-form-input
                        placeholder="P&A Budget Approved"
                        v-model="form.contract.pabapproved"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col col-md="3" sm="3">
                    <b-form-group label="P&A Distribution Support">
                      <b-form-input
                        type="number"
                        placeholder="P&A Distribution Support"
                        v-model="form.contract.padistribution"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="mt-1">
                  <b-col col-md="4" sm="4">
                    <b-form-group label="Revenue Share Type">
                      <v-select
                        placeholder="Revenue Share Type"
                        label="lbl_name"
                        :options="$store.state.master.revenue_share"
                        :reduce="(lbl) => lbl.lbl_id"
                        :close-on-select="true"
                        v-model="form.contract.revenue_share_type"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col col-md="12" sm="12">
                    <b-form-group label="Comment 3">
                      <b-form-textarea
                        placeholder="Comment 3"
                        v-model="form.contract.r_comment"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card>

            <!-- Theatrical Contract Options -->
            <!-- <b-card>
              <div>
                <b-row class="mt-1">
                  <b-col cols="12" md="6" lg="6" sm="10">
                    <b-form-checkbox
                      switch
                      inline
                      v-model="form.contract.deduction_from_rental_pa"
                      value="1"
                      unchecked-value="0"
                      >DEDUCTION P&A FROM CINEMATIC GROSS RECEIPTS (Cost off Top
                      Deal)
                    </b-form-checkbox>
                  </b-col>
                  <b-col cols="12" md="6" lg="6" sm="10">
                    <b-form-checkbox
                      switch
                      inline
                      v-model="form.contract.deduction_from_rental_mg"
                      value="1"
                      unchecked-value="0"
                      >DEDUCTION MG FROM CINEMATIC GROSS RECEIPTS
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col cols="12" md="6" lg="6" sm="10">
                    <b-form-checkbox
                      switch
                      inline
                      v-model="form.contract.distributor_share_status"
                      value="1"
                      unchecked-value="0"
                      >DISTRIBUTOR CORRIDOR PRIOR TO RECOUPEMENT OF P&A
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col cols="12" md="6" lg="6" sm="10">
                    <b-form-checkbox
                      switch
                      inline
                      v-model="form.contract.distributor_share_mg_status"
                      value="1"
                      unchecked-value="0"
                      >DISTRIBUTOR CORRIDOR PRIOR TO RECOUPEMENT OF MG
                    </b-form-checkbox>
                  </b-col>
                  <b-col cols="12" md="6" lg="6" sm="10">
                    <validation-provider
                      #default="{ errors }"
                      name="Distributor Share prior to recoupement on MG Percent"
                      rules="percent"
                    >
                      <b-form-input
                        type="number"
                        placeholder="Distributor Share prior to recoupement on MG Percent"
                        v-model="form.contract.distributor_share_mg_percent"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col cols="12" md="6" lg="6" sm="10">
                    <b-form-checkbox
                      switch
                      inline
                      v-model="
                        form.contract.distributor_commision_rental_status
                      "
                      value="1"
                      unchecked-value="0"
                      >DISTRIBUTOR CORRIDOR PRIOR TO RECOUPEMENT OF P&A and MG
                    </b-form-checkbox>
                  </b-col>
                  <b-col cols="12" md="6" lg="6" sm="10">
                    <validation-provider
                      #default="{ errors }"
                      name="Distributor Commission on Rental Percent"
                      rules="percent"
                    >
                      <b-form-input
                        type="number"
                        placeholder="Distributor Commission on Rental Percent"
                        v-model="
                          form.contract.distributor_commision_rental_percent
                        "
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col cols="12" md="6" lg="6" sm="10">
                    <b-form-checkbox
                      switch
                      inline
                      v-model="form.contract.licensor_share_status"
                      value="1"
                      unchecked-value="0"
                      >LICENSOR CORRIDOR PRIOR TO RECOUPEMENT OF
                      P&A</b-form-checkbox
                    >
                  </b-col>
                  <b-col cols="12" md="6" lg="6" sm="10">
                    <validation-provider
                      #default="{ errors }"
                      name="Licensor Share prior to recoupement Percent"
                      rules="percent"
                    >
                      <b-form-input
                        type="number"
                        placeholder="Licensor Share prior to recoupement Percent"
                        v-model="form.contract.licensor_share_recopmentPa"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col cols="12" md="6" lg="6" sm="10">
                    <b-form-checkbox
                      switch
                      inline
                      v-model="form.contract.licensor_share_mg_status"
                      value="1"
                      unchecked-value="0"
                      >LICENSOR CORRIDOR PRIOR TO RECOUPEMENT OF MG
                    </b-form-checkbox>

                  </b-col>
                  <b-col cols="12" md="6" lg="6" sm="10">
                    <validation-provider
                      #default="{ errors }"
                      name="Licensor's Share MG Percent"
                      rules="percent"
                    >
                      <b-form-input
                        type="number"
                        placeholder="Licensor's Share MG Percent"
                        v-model="form.contract.licensor_share_mg_percent"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
              </div>
            </b-card> -->
            <!-- Theatrical contract options end -->

            <b-row class="mb-1" v-if="currentContractSection === 'all'">
              <b-col cols="12" md="12">
                <b><b-badge pill variant="warning">Contract has </b-badge></b>
                <b-form-checkbox-group
                  class="mt-1"
                  :options="optionsCollaps"
                  switches
                  inline
                  v-model="selectedCollaps"
                ></b-form-checkbox-group>
              </b-col>
            </b-row>
            <b-card
              v-if="
                selectedCollaps.includes('Milestones') &&
                (currentContractSection === 'all' ||
                  currentContractSection === 'Milestones')
              "
              class="bg-color"
              border-variant="primary"
              ><b-card-text>
                <b-row>
                  <b-col col-md="4" sm="4">
                    <b-form-group label="MG">
                      <b-form-input
                        type="number"
                        placeholder="MG"
                        v-model="form.contract.mgadvance"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col col-md="4" sm="4">
                    <b-form-group label="MG Currency">
                      <v-select
                        placeholder="MG Currency"
                        label="lbl_name"
                        :options="$store.state.master.currency"
                        :reduce="(lbl) => lbl.lbl_id"
                        :close-on-select="true"
                        v-model="form.contract.c_currency"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col col-md="12" sm="12">
                    <!-- Milestones -->
                    <h6>
                      <b-badge variant="light-info">Milestones</b-badge>
                      <feather-icon
                        v-if="form.milestoneList.length <= 0"
                        style="cursor: pointer"
                        icon="PlusCircleIcon"
                        size="25"
                        @click="
                          () => {
                            form.milestoneList.push({
                              milestone_id: '',
                              payable: '',
                              amountpayable: '',
                              duedate: '',
                              actualdate: '',
                              comment: '',
                            });
                          }
                        "
                      />
                    </h6>

                    <table id="contractTablesStyle">
                      <tr>
                        <th style="width: 25%">Title</th>
                        <th style="width: 7%">%</th>
                        <th style="width: 15%">Amount</th>
                        <th style="width: 20%">Planned Date</th>
                        <th style="width: 20%">Actual Date</th>
                        <th style="width: 25%">Comment Milestone</th>
                        <th></th>
                      </tr>
                      <tr
                        v-for="(milestone, ml_index) in form.milestoneList"
                        :key="'milestone_' + ml_index"
                      >
                        <td>
                          <v-select
                            placeholder="Title"
                            label="lbl_name"
                            :options="$store.state.master.milestone"
                            :reduce="(label) => label.lbl_id"
                            :close-on-select="true"
                            v-model="milestone.milestone_id"
                          />
                        </td>
                        <td>
                          <b-form-input
                            placeholder="%"
                            v-model="milestone.payable"
                            @input="onPayableEntered($event, ml_index)"
                          />
                        </td>

                        <td>
                          <b-form-input
                            placeholder="Amount"
                            v-model="milestone.amountpayable"
                          />
                        </td>
                        <td>
                          <flat-pickr
                            :config="{
                              ...$flatPickrConfig,
                            }"
                            placeholder="Due Date"
                            class="form-control"
                            v-model="milestone.duedate"
                          />
                        </td>
                        <td>
                          <flat-pickr
                            :config="{
                              ...$flatPickrConfig,
                            }"
                            placeholder="Actual Date"
                            class="form-control"
                            v-model="milestone.actualdate"
                          />
                        </td>
                        <td>
                          <b-form-textarea
                            placeholder="Comment Milestone"
                            v-model="milestone.comment"
                          />
                        </td>
                        <td class="text-center">
                          <feather-icon
                            v-if="ml_index > 0"
                            style="cursor: pointer"
                            icon="MinusCircleIcon"
                            size="25"
                            @click="
                              () => {
                                form.milestoneList.splice(ml_index, 1);
                              }
                            "
                          />
                          <feather-icon
                            v-if="ml_index == form.milestoneList.length - 1"
                            style="cursor: pointer"
                            icon="PlusCircleIcon"
                            size="25"
                            @click="
                              () => {
                                form.milestoneList.push({
                                  milestone_id: '',
                                  payable: '',
                                  amountpayable: '',
                                  duedate: '',
                                  actualdate: '',
                                  comment: '',
                                });
                              }
                            "
                          />
                        </td>
                      </tr>
                    </table>
                  </b-col>
                </b-row> </b-card-text
            ></b-card>
            <b-card
              v-if="
                selectedCollaps.includes('Bumpers') &&
                (currentContractSection === 'all' ||
                  currentContractSection === 'Bumpers')
              "
              class="bg-color"
              border-variant="primary"
              ><b-card-text>
                <b-row>
                  <b-col col-md="12" sm="12">
                    <h6>
                      <b-badge variant="light-info">Bumpers</b-badge>
                      <feather-icon
                        v-if="form.bumpers.length <= 0"
                        style="cursor: pointer"
                        icon="PlusCircleIcon"
                        size="25"
                        @click="
                          () => {
                            form.bumpers.push({
                              bumper: '',
                              amount: '',
                              date: '',
                            });
                          }
                        "
                      />
                    </h6>

                    <table id="contractTablesStyle">
                      <tr>
                        <th style="width: 50%">Title</th>
                        <th style="width: 25%">Amount</th>
                        <th style="width: 25%">Due Date</th>
                        <th></th>
                      </tr>
                      <tr
                        v-for="(bumper, bp_index) in form.bumpers"
                        :key="'bumper_' + bp_index"
                      >
                        <td>
                          <v-select
                            placeholder="Bumper"
                            label="lbl_name"
                            :options="$store.state.master.bumper"
                            :reduce="(label) => label.lbl_id"
                            :close-on-select="true"
                            v-model="bumper.bumper"
                          />
                        </td>
                        <td>
                          <b-form-input
                            placeholder="Amount"
                            v-model="bumper.amount"
                          />
                        </td>
                        <td>
                          <flat-pickr
                            :config="{
                              ...$flatPickrConfig,
                            }"
                            placeholder="Due Date"
                            class="form-control"
                            v-model="bumper.date"
                          />
                        </td>
                        <td>
                          <feather-icon
                            v-if="bp_index > 0"
                            style="cursor: pointer"
                            icon="MinusCircleIcon"
                            size="25"
                            @click="
                              () => {
                                form.bumpers.splice(bp_index, 1);
                              }
                            "
                          />
                          <feather-icon
                            v-if="bp_index == form.bumpers.length - 1"
                            style="cursor: pointer"
                            icon="PlusCircleIcon"
                            size="25"
                            @click="
                              () => {
                                form.bumpers.push({
                                  bumper: '',
                                  amount: '',
                                  date: '',
                                });
                              }
                            "
                          />
                        </td>
                      </tr>
                    </table>
                  </b-col> </b-row></b-card-text
            ></b-card>
            <b-card
              v-if="
                selectedCollaps.includes('Statement_Period') &&
                (currentContractSection === 'all' ||
                  currentContractSection === 'Statement_Period')
              "
              class="bg-color"
              border-variant="primary"
              ><b-card-text>
                <b-row>
                  <b-col col-md="12" sm="12">
                    <h6>
                      <b-badge variant="light-info">Statement Period</b-badge>
                      <feather-icon
                        v-if="form.contract_statement.length <= 0"
                        style="cursor: pointer"
                        icon="PlusCircleIcon"
                        size="25"
                        @click="
                          () => {
                            form.contract_statement.push({
                              period: '',
                              duration: '',
                            });
                          }
                        "
                      />
                    </h6>

                    <table id="contractTablesStyle">
                      <tr>
                        <th style="width: 50%">Statement Period</th>
                        <th style="width: 50%">Duration (Months)</th>
                        <th></th>
                      </tr>
                      <tr
                        v-for="(statement, st_index) in form.contract_statement"
                        :key="'statement_' + st_index"
                      >
                        <td>
                          <v-select
                            label="label"
                            :reduce="(item) => item.value"
                            placeholder="Statement Period"
                            :options="statement_period"
                            :close-on-select="true"
                            v-model="statement.period"
                          />
                        </td>
                        <td>
                          <b-form-input
                            placeholder="Duration (Months)"
                            v-model="statement.duration"
                          />
                        </td>
                        <td>
                          <feather-icon
                            v-if="st_index > 0"
                            style="cursor: pointer"
                            icon="MinusCircleIcon"
                            size="25"
                            @click="
                              () => {
                                form.contract_statement.splice(st_index, 1);
                              }
                            "
                          />
                          <feather-icon
                            v-if="
                              st_index == form.contract_statement.length - 1
                            "
                            style="cursor: pointer"
                            icon="PlusCircleIcon"
                            size="25"
                            @click="
                              () => {
                                form.contract_statement.push({
                                  period: '',
                                  duration: '',
                                });
                              }
                            "
                          />
                        </td>
                      </tr>
                    </table>
                  </b-col> </b-row></b-card-text
            ></b-card>
            <b-card
              v-if="
                selectedCollaps.includes('Broadcast_Date') &&
                (currentContractSection === 'all' ||
                  currentContractSection === 'Broadcast_Date')
              "
              class="bg-color"
              border-variant="primary"
              ><b-card-text>
                <b-row>
                  <b-col col-md="12" sm="12">
                    <h6>
                      <b-badge variant="light-info">Broadcast Dates</b-badge>
                    </h6>

                    <table id="contractTablesStyle">
                      <tr>
                        <th>#</th>
                        <th style="width: 40%">Date</th>
                        <th>Figures</th>
                        <th></th>
                      </tr>
                      <tr
                        v-for="(bd, bd_index) in form.contract_broadcast"
                        :key="'broadcast_' + bd_index"
                      >
                        <td>{{ bd_index + 1 }}</td>
                        <td>
                          <flat-pickr
                            :config="{
                              ...$flatPickrConfig,
                            }"
                            placeholder="Date"
                            class="form-control"
                            v-model="bd.date"
                          />
                        </td>
                        <td>
                          <b-form-input
                            placeholder="Figures"
                            type="number"
                            v-model="bd.figures"
                          />
                        </td>
                        <td>
                          <feather-icon
                            v-if="bd_index > 0"
                            style="cursor: pointer"
                            icon="MinusCircleIcon"
                            size="25"
                            @click="
                              () => {
                                form.contract_broadcast.splice(bd_index, 1);
                              }
                            "
                          />
                          <feather-icon
                            v-if="
                              bd_index == form.contract_broadcast.length - 1
                            "
                            style="cursor: pointer"
                            icon="PlusCircleIcon"
                            size="25"
                            @click="
                              () => {
                                form.contract_broadcast.push({
                                  date: '',
                                  figures: '',
                                });
                              }
                            "
                          />
                        </td>
                      </tr>
                    </table>
                  </b-col> </b-row></b-card-text
            ></b-card>
            <b-card
              v-if="
                selectedCollaps.includes('Language_Access') &&
                (currentContractSection === 'all' ||
                  currentContractSection === 'Language_Access')
              "
              class="bg-color"
              border-variant="primary"
              ><b-card-text>
                <b-row>
                  <b-col col-md="12" sm="12">
                    <h6>
                      <b-badge variant="light-info">Language Access</b-badge>
                      <feather-icon
                        v-if="form.contract_access.length <= 0"
                        style="cursor: pointer"
                        icon="PlusCircleIcon"
                        size="25"
                        @click="
                          () => {
                            form.contract_access.push({
                              language: '',
                              access: '',
                            });
                          }
                        "
                      />
                    </h6>
                    <table id="contractTablesStyle">
                      <tr>
                        <th style="width: 50%">Language</th>
                        <th style="width: 50%">Access</th>
                        <th></th>
                      </tr>
                      <tr
                        v-for="(l_access, la_index) in form.contract_access"
                        :key="'la_index' + la_index"
                      >
                        <td>
                          <b-form-input
                            placeholder="Language"
                            v-model="l_access.language"
                          />
                        </td>
                        <td>
                          <b-form-input
                            placeholder="Access"
                            v-model="l_access.access"
                          />
                        </td>
                        <td>
                          <feather-icon
                            v-if="la_index > 0"
                            style="cursor: pointer"
                            icon="MinusCircleIcon"
                            size="25"
                            @click="
                              () => {
                                form.contract_access.splice(la_index, 1);
                              }
                            "
                          />
                          <feather-icon
                            v-if="la_index == form.contract_access.length - 1"
                            style="cursor: pointer"
                            icon="PlusCircleIcon"
                            size="25"
                            @click="
                              () => {
                                form.contract_access.push({
                                  language: '',
                                  access: '',
                                });
                              }
                            "
                          />
                        </td>
                      </tr>
                    </table>
                  </b-col> </b-row></b-card-text
            ></b-card>
            <b-card
              v-if="
                selectedCollaps.includes('Finished_Product') &&
                (currentContractSection === 'all' ||
                  currentContractSection === 'Finished_Product')
              "
              class="bg-color"
              border-variant="primary"
              ><b-card-text>
                <b-row class="mt-1">
                  <b-col col-md="4" sm="4">
                    <b-form-group>
                      <b-form-checkbox
                        switch
                        inline
                        v-model="form_dynamics.is_finished_product"
                        >Purchase of finished products from
                        licensor</b-form-checkbox
                      >
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row v-if="form_dynamics.is_finished_product">
                  <b-col col-md="12" sm="12">
                    <h6>
                      <b-badge variant="light-info">Finished Product</b-badge>
                      <feather-icon
                        v-if="form.contract_products.length <= 0"
                        style="cursor: pointer"
                        icon="PlusCircleIcon"
                        size="25"
                        @click="
                          () => {
                            form.contract_products.push({
                              product: '',
                              price: '',
                              currency: '',
                              flicensor: '',
                              licensor: '',
                            });
                          }
                        "
                      />
                    </h6>
                    <table id="contractTablesStyle">
                      <tr>
                        <th style="width: 25%">Product</th>
                        <th>Price</th>
                        <th style="width: 15%">Currency</th>
                        <th style="width: 20%">Free Samples for licensor</th>
                        <th style="width: 25%">Flat price for Licensor</th>
                        <th></th>
                      </tr>
                      <tr
                        v-for="(prod, prod_index) in form.contract_products"
                        :key="'prod_' + prod_index"
                      >
                        <td>
                          <b-form-input
                            placeholder="Product"
                            v-model="prod.product"
                          />
                        </td>

                        <td>
                          <b-form-input
                            placeholder="Price"
                            v-model="prod.price"
                          />
                        </td>
                        <td>
                          <b-form-input
                            placeholder="Currency"
                            v-model="prod.currency"
                          />
                        </td>
                        <td>
                          <b-form-input
                            placeholder="Free Samples for licensor"
                            v-model="prod.flicensor"
                          />
                        </td>
                        <td>
                          <b-form-input
                            placeholder="Flat price for Licensor"
                            v-model="prod.licensor"
                          />
                        </td>
                        <td>
                          <feather-icon
                            v-if="prod_index > 0"
                            style="cursor: pointer"
                            icon="MinusCircleIcon"
                            size="25"
                            @click="
                              () => {
                                form.contract_products.splice(prod_index, 1);
                              }
                            "
                          />
                          <feather-icon
                            v-if="
                              prod_index == form.contract_products.length - 1
                            "
                            style="cursor: pointer"
                            icon="PlusCircleIcon"
                            size="25"
                            @click="
                              () => {
                                form.contract_products.push({
                                  product: '',
                                  price: '',
                                  currency: '',
                                  flicensor: '',
                                  licensor: '',
                                });
                              }
                            "
                          />
                        </td>
                      </tr>
                    </table>
                  </b-col> </b-row></b-card-text
            ></b-card>
            <b-card
              v-if="
                selectedCollaps.includes('Holdback') &&
                (currentContractSection === 'all' ||
                  currentContractSection === 'Holdback')
              "
              class="bg-color"
              border-variant="primary"
              ><b-card-text>
                <b-row>
                  <b-col col-md="12" sm="12">
                    <h6>
                      <b-badge variant="light-info">Holdback</b-badge>
                      <feather-icon
                        v-if="form.hold_back.length <= 0"
                        style="cursor: pointer"
                        icon="PlusCircleIcon"
                        size="25"
                        @click="
                          () => {
                            form.hold_back.push({
                              title: '',
                              right: [],
                              comment: '',
                              start_date: '',
                              end_date: '',
                            });
                          }
                        "
                      />
                    </h6>
                    <table id="contractTablesStyle">
                      <tr>
                        <th>Rights</th>
                        <th>Territory</th>
                        <th style="width: 15%">Start Date</th>
                        <th style="width: 15%">End Date</th>
                        <th style="width: 30%">Comment Holdback</th>
                        <th></th>
                      </tr>
                      <tr
                        v-for="(holdback, hb_index) in form.hold_back"
                        :key="'holdback_' + hb_index"
                      >
                        <td>
                          <treeselect
                            :options="holdBackRights"
                            multiple
                            placeholder="Rights"
                            v-model="holdback.right"
                          />
                        </td>

                        <td>
                          <treeselect
                            :options="holdBackTerritories"
                            multiple
                            placeholder="Territory"
                            v-model="holdback.territory"
                          />
                        </td>

                        <td>
                          <flat-pickr
                            :config="{
                              ...$flatPickrConfig,
                            }"
                            placeholder="Start Date"
                            class="form-control"
                            v-model="holdback.start_date"
                          />
                        </td>
                        <td>
                          <flat-pickr
                            :config="{
                              ...$flatPickrConfig,
                            }"
                            placeholder="End Date"
                            class="form-control"
                            v-model="holdback.end_date"
                          />
                        </td>
                        <td>
                          <b-form-textarea
                            placeholder="Comment Holdback"
                            v-model="holdback.comment"
                          />
                        </td>
                        <td>
                          <feather-icon
                            v-if="hb_index > 0"
                            style="cursor: pointer"
                            icon="MinusCircleIcon"
                            size="25"
                            @click="
                              () => {
                                form.hold_back.splice(hb_index, 1);
                              }
                            "
                          />
                          <feather-icon
                            v-if="hb_index == form.hold_back.length - 1"
                            style="cursor: pointer"
                            icon="PlusCircleIcon"
                            size="25"
                            @click="
                              () => {
                                form.hold_back.push({
                                  title: '',
                                  right: [],
                                  comment: '',
                                  start_date: '',
                                  end_date: '',
                                });
                              }
                            "
                          />
                        </td>
                      </tr>
                    </table>
                  </b-col> </b-row></b-card-text
            ></b-card>
            <b-card
              v-if="
                selectedCollaps.includes('Support') &&
                (currentContractSection === 'all' ||
                  currentContractSection === 'Support')
              "
              class="bg-color"
              border-variant="primary"
              ><b-card-text>
                <b-row>
                  <b-col col-md="12" sm="12">
                    <h6>
                      <b-badge variant="light-info">Support</b-badge>
                      <feather-icon
                        v-if="form.support_item.length <= 0"
                        style="cursor: pointer"
                        icon="PlusCircleIcon"
                        size="25"
                        @click="
                          () => {
                            form.support_item.push({
                              title: `Support ${form.support_item.length + 1}`,
                              status1: '',
                              date1: '',
                              date2: '',
                            });
                          }
                        "
                      />
                    </h6>

                    <table id="contractTablesStyle">
                      <tr>
                        <th style="width: 6%">Applied</th>
                        <th style="width: 30%">Title</th>
                        <th style="width: 15%">Deadline Date</th>
                        <th style="width: 15%">Deadline Date 1</th>
                        <th>Uploads</th>
                        <th></th>
                      </tr>
                      <tr
                        v-for="(support, sp_index) in form.support_item"
                        :key="'support_' + sp_index"
                      >
                        <td>
                          <b-form-checkbox
                            switch
                            inline
                            v-model="support.status1"
                          />
                        </td>
                        <td>
                          <b-form-input
                            placeholder="Title"
                            v-model="support.title"
                          />
                        </td>
                        <td>
                          <flat-pickr
                            :config="{
                              ...$flatPickrConfig,
                            }"
                            placeholder="Deadline Date"
                            class="form-control"
                            v-model="support.date1"
                          />
                        </td>
                        <td>
                          <flat-pickr
                            :config="{
                              ...$flatPickrConfig,
                            }"
                            placeholder="Deadline Date 1"
                            class="form-control"
                            v-model="support.date2"
                          />
                        </td>
                        <td>
                          <files-container v-model="support.file" />
                        </td>
                        <td class="text-center">
                          <feather-icon
                            v-if="sp_index > 0"
                            style="cursor: pointer"
                            icon="MinusCircleIcon"
                            size="25"
                            @click="
                              () => {
                                form.support_item.splice(sp_index, 1);
                              }
                            "
                          />
                          <feather-icon
                            v-if="sp_index == form.support_item.length - 1"
                            style="cursor: pointer"
                            icon="PlusCircleIcon"
                            size="25"
                            @click="
                              () => {
                                form.support_item.push({
                                  title: `Support ${
                                    form.support_item.length + 1
                                  }`,
                                  status1: '',
                                  date1: '',
                                  date2: '',
                                });
                              }
                            "
                          />
                        </td>
                      </tr>
                    </table>
                  </b-col> </b-row></b-card-text
            ></b-card>
            <b-card
              v-if="
                selectedCollaps.includes('Assets') &&
                (currentContractSection === 'all' ||
                  currentContractSection === 'Assets')
              "
              border-variant="primary"
              ><b-card-text>
                <b-row>
                  <b-col col-md="12" sm="12">
                    <b-badge class="mb-1" variant="light-info">Assets</b-badge>
                    <files-container v-model="form.contract.assets" />
                  </b-col> </b-row></b-card-text
            ></b-card>
          </validation-observer>
        </tab-content>
        <!-- Revenue Share Tab -->
        <tab-content title="Revenue Shares">
          <b-card border-variant="primary">
            <b-row>
              <b-col cols="12" md="6" lg="6" sm="12">
                <b-form-group label="Show Table By ">
                  <v-select
                    :options="revenueShareTableShowByOptions"
                    multiple
                    :disabled="false"
                    v-model="form.trl_combination"
                    :close-on-select="false"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6" sm="12">
                <b-form-group label="Update By Group">
                  <v-select
                    :options="revenuShareGroupOptions"
                    v-model="revenuShareGroupValue"
                    :disabled="true"
                    @input="
                      () => {
                        $nextTick(() => computeRevenuShareItems());
                      }
                    "
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-table
              size="sm"
              responsive
              bordered
              class="position-relative"
              primary-key="id"
              empty-text="No matching records found"
              show-empty
              :items="revenueSharesTableItems"
              :fields="revenueSharesFields"
            >
              <template #head(index)> # </template>

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(right)="data">
                {{ data.item.right_name }}
                <feather-icon
                  v-if="data.item.rightHasChild === '1'"
                  style="cursor: pointer"
                  icon="PlusCircleIcon"
                  size="25"
                  @click="expandRightParent(data.item)"
                />
                <feather-icon
                  v-else-if="data.item.isCollapsible === '1'"
                  style="cursor: pointer"
                  icon="MinusCircleIcon"
                  size="25"
                  @click="collapseRightParent(data.item)"
                />
              </template>

              <template #cell(territory)="data">
                {{ data.item.territory_name }}
              </template>

              <template #cell(language)="data">
                {{ data.item.language_name }}
              </template>

              <template #cell(share)="data">
                <b-row>
                  <b-col>
                    <validation-provider
                      #default="{ errors }"
                      name="Licensor's Share"
                      rules="percent"
                    >
                      <b-row>
                        <b-col sm="3">
                          <label class="text-muted">Licensor's Share :</label>
                        </b-col>
                        <b-col sm="9">
                          <b-form-input
                            type="number"
                            placeholder="Licensor's Share"
                            :value="data.item.licensor_share"
                            @input="
                              setRevenuShareItemValue(
                                'licensor_share',
                                $event,
                                data.item
                              )
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-col>
                      </b-row>
                    </validation-provider>
                  </b-col>
                </b-row>
                <div v-if="checkIfTheatricalRight(data.item.right)">
                  <b-row class="mt-1">
                    <b-col cols="12" md="6" lg="6" sm="10">
                      <b-form-checkbox
                        switch
                        inline
                        :checked="data.item.deduction_from_rental_pa"
                        value="1"
                        unchecked-value="0"
                        @input="
                          setRevenuShareItemValue(
                            'deduction_from_rental_pa',
                            $event,
                            data.item
                          )
                        "
                        >DEDUCTION P&A FROM CINEMATIC GROSS RECEIPTS (Cost off
                        Top Deal)
                      </b-form-checkbox>
                    </b-col>
                    <b-col cols="12" md="6" lg="6" sm="10">
                      <b-form-checkbox
                        switch
                        inline
                        :checked="data.item.deduction_from_rental_mg"
                        value="1"
                        unchecked-value="0"
                        @input="
                          setRevenuShareItemValue(
                            'deduction_from_rental_mg',
                            $event,
                            data.item
                          )
                        "
                        >DEDUCTION MG FROM CINEMATIC GROSS RECEIPTS
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col cols="12" md="6" lg="6" sm="10">
                      <b-form-checkbox
                        switch
                        inline
                        :checked="data.item.distributor_share_status"
                        value="1"
                        unchecked-value="0"
                        @input="
                          setRevenuShareItemValue(
                            'distributor_share_status',
                            $event,
                            data.item
                          )
                        "
                        >DISTRIBUTOR CORRIDOR PRIOR TO RECOUPEMENT OF P&A
                      </b-form-checkbox>
                    </b-col>
                    <b-col cols="12" md="6" lg="6" sm="10">
                      <validation-provider
                        #default="{ errors }"
                        name="Disitributor's Share Percent"
                        rules="percent"
                      >
                        <b-form-input
                          type="number"
                          placeholder="Disitributor's Share Percent"
                          :value="data.item.distributor_share"
                          @input="
                            setRevenuShareItemValue(
                              'distributor_share',
                              $event,
                              data.item
                            )
                          "
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col cols="12" md="6" lg="6" sm="10">
                      <b-form-checkbox
                        switch
                        inline
                        :checked="data.item.distributor_share_mg_status"
                        value="1"
                        unchecked-value="0"
                        @input="
                          setRevenuShareItemValue(
                            'distributor_share_mg_status',
                            $event,
                            data.item
                          )
                        "
                        >DISTRIBUTOR CORRIDOR PRIOR TO RECOUPEMENT OF MG
                      </b-form-checkbox>
                    </b-col>
                    <b-col cols="12" md="6" lg="6" sm="10">
                      <validation-provider
                        #default="{ errors }"
                        name="Distributor Share prior to recoupement on MG Percent"
                        rules="percent"
                      >
                        <b-form-input
                          type="number"
                          placeholder="Distributor Share prior to recoupement on MG Percent"
                          :value="data.item.distributor_share_mg_percent"
                          @input="
                            setRevenuShareItemValue(
                              'distributor_share_mg_percent',
                              $event,
                              data.item
                            )
                          "
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col cols="12" md="6" lg="6" sm="10">
                      <b-form-checkbox
                        switch
                        inline
                        :checked="data.item.distributor_commision_rental_status"
                        value="1"
                        unchecked-value="0"
                        @input="
                          setRevenuShareItemValue(
                            'distributor_commision_rental_status',
                            $event,
                            data.item
                          )
                        "
                        >DISTRIBUTOR CORRIDOR PRIOR TO RECOUPEMENT OF P&A and MG
                      </b-form-checkbox>
                    </b-col>
                    <b-col cols="12" md="6" lg="6" sm="10">
                      <validation-provider
                        #default="{ errors }"
                        name="Distributor Commission on Rental Percent"
                        rules="percent"
                      >
                        <b-form-input
                          type="number"
                          placeholder="Distributor Commission on Rental Percent"
                          :value="
                            data.item.distributor_commision_rental_percent
                          "
                          @input="
                            setRevenuShareItemValue(
                              'distributor_commision_rental_percent',
                              $event,
                              data.item
                            )
                          "
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col cols="12" md="6" lg="6" sm="10">
                      <b-form-checkbox
                        switch
                        inline
                        :checked="data.item.licensor_share_status"
                        value="1"
                        unchecked-value="0"
                        @input="
                          setRevenuShareItemValue(
                            'licensor_share_status',
                            $event,
                            data.item
                          )
                        "
                        >LICENSOR CORRIDOR PRIOR TO RECOUPEMENT OF
                        P&A</b-form-checkbox
                      >
                    </b-col>
                    <b-col cols="12" md="6" lg="6" sm="10">
                      <validation-provider
                        #default="{ errors }"
                        name="Licensor Share prior to recoupement Percent"
                        rules="percent"
                      >
                        <b-form-input
                          type="number"
                          placeholder="Licensor Share prior to recoupement Percent"
                          :value="data.item.licensor_share_recopmentPa"
                          @input="
                            setRevenuShareItemValue(
                              'licensor_share_recopmentPa',
                              $event,
                              data.item
                            )
                          "
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col cols="12" md="6" lg="6" sm="10">
                      <b-form-checkbox
                        switch
                        inline
                        :checked="data.item.licensor_share_mg_status"
                        value="1"
                        unchecked-value="0"
                        @input="
                          setRevenuShareItemValue(
                            'licensor_share_mg_status',
                            $event,
                            data.item
                          )
                        "
                        >LICENSOR CORRIDOR PRIOR TO RECOUPEMENT OF MG
                      </b-form-checkbox>
                    </b-col>
                    <b-col cols="12" md="6" lg="6" sm="10">
                      <validation-provider
                        #default="{ errors }"
                        name="Licensor's Share MG Percent"
                        rules="percent"
                      >
                        <b-form-input
                          type="number"
                          placeholder="Licensor's Share MG Percent"
                          :value="data.item.licensor_share_mg_percent"
                          @input="
                            setRevenuShareItemValue(
                              'licensor_share_mg_percent',
                              $event,
                              data.item
                            )
                          "
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </div>
              </template>

              <template #cell(exclusive)="data">
                <b-form-checkbox
                  switch
                  inline
                  :checked="data.value"
                  :unchecked-value="false"
                  @change="
                    setRevenuShareItemValue('exclusive', $event, data.item)
                  "
                />
              </template>
            </b-table>
          </b-card>
        </tab-content>
        <b-button
          v-if="showDraftButton == true"
          variant="success"
          @click="saveContractAsDraft"
          class="save-as-draft-btn mt-1 ml-1"
        >
          Save As Draft
        </b-button>
      </form-wizard>
    </div>
    <b-modal
      size="xl"
      title="Validation Error"
      ref="validationErrorModal"
      hide-footer
    >
      <section v-if="validationError.data && validationError.data.length">
        <div v-for="(vd, index) in validationError.data" :key="'vd_' + index">
          <h4 v-if="vd.message">{{ vd.message }}</h4>
          <table class="table" v-if="vd.data && vd.data.length">
            <tr>
              <th v-if="vd.data[0].id">Contract Id</th>
              <th v-if="vd.data[0].movie_title">Title</th>
              <th v-if="vd.data[0].company">Company</th>
              <th v-if="vd.data[0].territory_name">Territory</th>
              <th v-if="vd.data[0].right_name">Right</th>
              <th v-if="vd.data[0].lang_name">Language</th>
            </tr>
            <tr v-for="(i, index) in vd.data" :key="'ve_' + index">
              <td v-if="i.id">{{ i.id }}</td>
              <td v-if="i.movie_title">{{ i.movie_title }}</td>
              <td v-if="i.company">{{ i.company }}</td>
              <td v-if="i.territory_name">{{ i.territory_name }}</td>
              <td v-if="i.right_name">{{ i.right_name }}</td>
              <td v-if="i.lang_name">{{ i.lang_name }}</td>
            </tr>
          </table>
        </div>
      </section>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardText,
  BFormDatepicker,
  BFormTextarea,
  BFormSelect,
  BFormSelectOption,
  BImg,
  BTable,
  BBadge,
  BAlert,
  BCollapse,
  BFormCheckboxGroup,
  BTabs,
  BTab,
} from "bootstrap-vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import Treeselect from "@riophae/vue-treeselect";
import Ripple from "vue-ripple-directive";
import * as ContractServices from "@/apiServices/ContractServices";
import vSelect from "vue-select";
import FilesContainer from "@/views/components/FilesUpload/FilesContainer.vue";

import {
  labels,
  contract_natures,
  statement_period,
  contract_natures_obj,
} from "@/utils/constants";
import { reduceContractFormData } from "./contractsHelpers";
import flatPickr from "vue-flatpickr-component";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, percent } from "@validations";
import ContractDetails from "./ContractsDetails.vue";
import showModal from "../../components/ModalConfirmation";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    BCardText,
    BFormTextarea,
    BFormDatepicker,
    vSelect,
    Treeselect,
    // TreeSelectWithGroups,
    flatPickr,
    FeatherIcon,
    BFormSelect,
    BFormSelectOption,
    BImg,
    FormWizard,
    TabContent,
    BTable,
    ValidationProvider,
    ValidationObserver,
    BBadge,
    BAlert,
    BCollapse,
    BFormCheckboxGroup,
    FilesContainer,
    BTabs,
    BTab,
    ContractDetails,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      is_copy: false,
      contract_id: null,
      showDraftButton: false,
      form: {
        draft: false,

        contract: {
          contract_nature: "",
          movie_id: [],
          vendor_id: "",
          contract_type: "",
          offer_date: "",
          contract_date: "",
          effective_date: "",
          contract_comment: "",
          contract_begin: "",
          contract_end: "",
          duration_year: "",
          duration_month: "",
          duration_comment: "",
          nod: "",
          sellofperiod: "",
          number_of_run: "",
          material_delivery: "",
          extension_clause: "",
          extension_date: "",
          extension_clause_desc: "",
          revenue_share_type: "",
          mgadvance: "",
          c_currency: "",
          r_comment: "",
          pacap: "",
          painternal: "",
          padistribution: "",
          pabapproved: "",
          assets: [],
          languages: [],
          subtitles: [],

          // deduction_from_rental_pa: "0",
          // deduction_from_rental_mg: "0",
          // distributor_share_status: "0",
          // distributor_share_mg_status: "0",
          // distributor_share_mg_percent: "",
          // distributor_commision_rental_status: "0",
          // distributor_commision_rental_percent: "",
          // licensor_share_status: "0",
          // licensor_share_mg_status: "0",
          // licensor_share_recopmentPa: "",
          // licensor_share_mg_percent: "",
        },

        trl_combination: ["Right"],

        sub_contract: false,
        main_contract_id: "",

        contract_broadcast: [
          {
            date: "",
            figures: "",
          },
          {
            date: "",
            figures: "",
          },
        ],

        trls: [
          {
            territory: [],
            right: [],
            language: [],
            subtitle: [],
            exclusive: false,
            comment: "",
          },
        ],

        milestoneList: [
          {
            milestone_id: "",
            amountpayable: "",
            payable: "",
            duedate: "",
            actualdate: "",
            comment: "",
          },
        ],

        bumpers: [
          {
            bumper: "",
            amount: "",
            date: "",
          },
        ],

        contract_statement: [
          {
            period: "",
            duration: "",
          },
        ],

        contract_access: [
          {
            language: "",
            access: "",
          },
        ],

        contract_products: [
          {
            product: "",
            price: "",
            currency: "",
            flicensor: "",
            licensor: "",
          },
        ],

        hold_back: [
          {
            title: "",
            right: [],
            territory: [],
            comment: "",
            start_date: "",
            end_date: "",
          },
        ],

        support_item: [
          {
            title: "",
            status1: "",
            file: [],
            date1: "",
            date2: "",
          },
        ],

        contract_approval: [],
      },

      acquiredTrls: [],
      availableTrls: [],

      trls_options: [],

      trl_territories: [],
      trl_rights: [],
      trl_languages: [],

      contract_natures: contract_natures,
      statement_period: statement_period,
      form_dynamics: {
        is_finished_product: false,
        vendor_label: "Licensor/Licensee",
        min_date: "",
        max_date: "",
      },
      revenueShareTableShowByOptions: ["Right", "Territory", "Language"],
      revenuShareGroupOptions: ["Right", "Territory"],
      revenuShareGroupValue: "",
      // revenueSharesFields: [
      //   {
      //     key: "index",
      //   },
      //   {
      //     key: "right",
      //     label: "Right",
      //     sortable: true,
      //     thStyle: { width: "20%" },
      //   },
      //   {
      //     key: "share",
      //     Label: "Licensor Share",
      //   },
      //   {
      //     key: "exclusive",
      //     Label: "Exclusive",
      //     thStyle: { width: "3%" },
      //   },
      // ],
      optionTypes: [
        {
          Id: "Y",
          value: "Yes",
        },
        {
          Id: "N",
          value: "No",
        },
      ],
      revenueSharesTableItems: [],
      revenueSharesAllItems: [],

      optionsCollaps: [
        { text: "Milestones", value: "Milestones" },
        { text: "Bumpers", value: "Bumpers" },
        {
          text: "Statement Period",
          value: "Statement_Period",
          // disabled: true
        },
        {
          text: "Broadcast Date",
          value: "Broadcast_Date",
          // disabled: true
        },
        { text: "Language Access", value: "Language_Access" },
        { text: "Finished Product", value: "Finished_Product" },
        { text: "Holdback", value: "Holdback" },
        { text: "Financial Support", value: "Support" },
        { text: "Assets", value: "Assets" },
      ],

      currentContractCategory: "full_contract",
      currentContractSection: "all",
      contractCategories: [
        { label: "Preview & Logs", key: "preview" },
        { label: "Contract Details", key: "full_contract" },
        { label: "Revenue Shares", key: "revenue_shares" },
      ],
      contractSections: [
        { label: "Milestones", key: "Milestones" },
        { label: "Bumpers", key: "Bumpers" },
        {
          label: "Statement Period",
          key: "Statement_Period",
          // disabled: true
        },
        {
          label: "Broadcast Date",
          key: "Broadcast_Date",
          // disabled: true
        },
        { label: "Language Access", key: "Language_Access" },
        { label: "Finished Product", key: "Finished_Product" },
        { label: "Holdback", key: "Holdback" },
        { label: "Financial Support", key: "Support" },
        { label: "Assets", key: "Assets" },
      ],

      selectedCollaps: [
        "Milestones",
        "Bumpers",
        "Statement_Period",
        "Broadcast_Date",
        "Language_Access",
        "Holdback",
      ],
      contracts_select_list: [],
      validationError: {
        message: "",
        data: [],
      },

      required,
      percent,
    };
  },

  watch: {
    "form.trl_combination": function (newVal) {
      this.computeRevenuShareItems();
    },
  },

  computed: {
    revenueSharesFields() {
      const feilds = [];
      const trls_combination = this.form.trl_combination;
      if (trls_combination && trls_combination.length) {
        feilds.push({
          key: "index",
        });

        for (let i = 0; i < trls_combination.length; i++) {
          const element = trls_combination[i];
          feilds.push({
            key: element.toLowerCase(),
            label: element,
            sortable: true,
            thStyle: { width: "20%" },
          });
        }

        feilds.push({
          key: "share",
          Label: "Licensor Share",
        });
        if (
          this.form.contract.contract_nature &&
          this.form.contract.contract_nature === contract_natures_obj.Sales
        ) {
          feilds.push({
            key: "exclusive",
            Label: "Exclusive",
            thStyle: { width: "3%" },
          });
        }

        return feilds;
      }
    },

    files_url() {
      return process.env.VUE_APP_FILESURL;
    },

    natureSelected() {
      return this.form.contract.contract_nature ? false : true;
    },

    movieSelected() {
      return !this.natureSelected && this.form.contract.movie_id.length
        ? false
        : true;
    },

    licenseBeginSelected() {
      return !this.movieSelected && this.form.contract.contract_begin
        ? false
        : true;
    },

    holdBackTerritories() {
      const territoriesTree = this.$store.state.master.territory_tree;
      let selectedTerritories = [];
      this.form.trls.forEach((trls) => {
        selectedTerritories = selectedTerritories.concat(trls.territory);
      });
      const filteredTerritory = [];
      for (const territory of territoriesTree) {
        if (selectedTerritories.includes(territory.id)) {
          filteredTerritory.push(territory);
          continue;
        }
        if (!territory.children || !territory.children.length) continue;
        for (const child of territory.children) {
          if (selectedTerritories.includes(child.id)) {
            filteredTerritory.push(child);
          }
        }
      }
      return filteredTerritory;
    },

    holdBackRights() {
      const rightsTree = this.$store.state.master.rights_tree;
      let selectedRights = [];
      this.form.trls.forEach((trls) => {
        selectedRights = selectedRights.concat(trls.right);
      });
      const filteredRights = [];
      for (const right of rightsTree) {
        if (selectedRights.includes(right.id)) {
          filteredRights.push(right);
          continue;
        }
        if (!right.children || !right.children.length) continue;
        for (const child of right.children) {
          if (selectedRights.includes(child.id)) {
            filteredRights.push(child);
          }
        }
      }
      return filteredRights;
    },
  },

  methods: {
    showModal,

    addTrls() {
      this.form.trls.push({
        territory: [],
        right: [],
        language: [],
        subtitle: [],
        exclusive: false,
        comment: "",
      });
      this.trls_options.push({
        trl_languages: this.$store.state.master.language,
        trl_rights: this.$store.state.master.rights_tree,
        trl_territories: this.$store.state.master.territory_tree,
      });
    },

    removeTrls(trls_index) {
      this.form.trls.splice(trls_index, 1);
      this.trls_options.splice(trls_index, 1);
    },

    async onTrlTerritorySelect(i, trls_index) {
      try {
        await this.nextTickSync();

        let territoriesTree = this.trls_options[trls_index].trl_territories;

        const territory_ids = this.form.trls[trls_index].territory;

        // Here getting option object of all territory selected and
        // getting main territory object if sub-territory is selected
        const selectedTerr = JSON.parse(
          JSON.stringify(this.trl_territories)
        ).filter((i) => {
          if (territory_ids.includes(i.id)) {
            return true;
          } else {
            if (i.children?.length) {
              for (const c of i.children) {
                if (territory_ids.includes(c.id)) {
                  return true;
                }
              }
            }
          }
          return false;
        });

        // Getting all territory and group ids that need to be disabled
        const terrNeedsDisabling = new Map();
        for (const st of selectedTerr) {
          // If st is group
          // then all its childrens and overlapping groups need to be disabled
          if (st.lbl_is_group && st.children?.length) {
            // Disabling childrens
            for (const c of st.children) {
              terrNeedsDisabling.set(`${c.lbl_id}`, true);
            }
          }

          for (const t of territoriesTree) {
            // If t is a group and not equal to st
            if (
              t.lbl_is_group &&
              t.children?.length &&
              t.lbl_id !== st.lbl_id
            ) {
              for (const c of t.children) {
                // If st is also a group
                // t needs to be disabled if st and t have overlapping territories
                // terrNeedsDisabling contains all childrens of st group at this point
                if (terrNeedsDisabling.has(`${c.lbl_id}`)) {
                  terrNeedsDisabling.set(`${t.lbl_id}`, true);

                  // checking for only single occurence is enough
                  break;
                }
                // Else if st is territory
                // t group needs to be disabled if it contains st
                if (c.lbl_id === st.lbl_id) {
                  terrNeedsDisabling.set(`${t.lbl_id}`, true);
                  break;
                }
              }
            }
          }
        }

        const tree = this.trl_territories.map((i) => {
          return {
            ...i,
            isDisabled: terrNeedsDisabling.has(`${i.lbl_id}`) ? "Y" : false,
          };
        });

        this.trls_options[trls_index].trl_territories = [];
        this.trls_options[trls_index].trl_territories = tree;

        // const territoriesTree = this.trl_territories;
      } catch (error) {
        console.error(`Error in onTrlTerritorySelect `, error);
      }
    },

    setContractCategory(key) {
      if (key === "revenue_shares") {
        this.currentContractCategory = key;
        if (this.$refs.wizard) this.$refs.wizard.nextTab();
      }
      if (key === "full_contract") {
        this.currentContractCategory = key;
        if (this.$refs.wizard) this.$refs.wizard.prevTab();
      }
      this.currentContractCategory = key;
    },

    setContractSection(key) {
      this.currentContractSection = key;
      if (this.$refs.wizard) this.$refs.wizard.prevTab();
    },

    getSelectedContractOptions() {
      let contractSections = this.contractSections;
      const selectedContractSections = contractSections.filter((section) =>
        this.selectedCollaps.includes(section.key)
      );

      return [{ label: "All", key: "all" }, ...selectedContractSections];
    },

    async getContractsSelectList(input) {
      if (input) {
        const res = await ContractServices.GetSelectList();
        this.contracts_select_list = res.data.data;
      } else {
        this.form.main_contract_id = "";
      }
    },

    movieFilter: (option, label, search) => {
      let temp = search.toLowerCase();
      return (
        (option.unique &&
          option.unique.toString().toLowerCase().indexOf(temp) > -1) ||
        (option.title && option.title.toLowerCase().indexOf(temp) > -1) ||
        (option.international_title &&
          option.international_title.toLowerCase().indexOf(temp) > -1)
      );
    },

    getTabIndex(prevIndex, nextIndex) {
      if (nextIndex == "1") {
        this.showDraftButton = true;
        this.currentContractCategory = "revenue_shares";
      } else {
        this.showDraftButton = false;
      }
    },

    // Contract Details Validation and Dynamics Function
    onContractNatureChange(nature) {
      if (nature === contract_natures_obj.Sales) {
        this.form.contract.movie_id = [];
        this.$store.dispatch("master/setMoviesMaster", { sales: true });
      } else {
        this.$store.dispatch("master/setMoviesMaster");
      }
    },

    async getAcquiredTrl() {
      this.$nextTick(async () => {
        try {
          if (
            this.form.contract.movie_id.length &&
            this.form.contract.contract_begin &&
            this.form.contract.contract_end
          ) {
            let res;
            if (
              this.form.contract.contract_nature ===
              contract_natures_obj.Acquisition
            ) {
              res = await ContractServices.GetAcquiredTrl({
                contract_id: this.contract_id,
                movie_id: this.form.contract.movie_id,
                contract_begin: this.form.contract.contract_begin,
                contract_end: this.form.contract.contract_end,
              });
            } else if (
              this.form.contract.contract_nature === contract_natures_obj.Sales
            ) {
              res = await ContractServices.getAvailableTrl({
                movie_ids: this.form.contract.movie_id,
                start_date: this.form.contract.contract_begin,
                end_date: this.form.contract.contract_end,
              });
            }

            if (!res || !res.data.status) {
              return;
            }

            const trl = res.data.data;

            const trls = [];
            for (const i of trl) {
              trls.push({
                territory: i.territory_ids,
                right: i.right_ids,
                language: i.language_ids,
                subtitle: [],
                exclusive: false,
                comment: "",
              });
            }

            if (
              this.form.contract.contract_nature ===
              contract_natures_obj.Acquisition
            ) {
              this.acquiredTrls = trls;
            } else if (
              this.form.contract.contract_nature === contract_natures_obj.Sales
            ) {
              this.availableTrls = trls;
            }
          }
        } catch (error) {
          console.error(`Error in onLicenseEnd `, error);
        }
      });
    },

    onPayableEntered(e, ml_index) {
      const mgadvance = this.form.contract.mgadvance;
      if (mgadvance && !isNaN(mgadvance)) {
        if (e && !isNaN(e)) {
          this.form.milestoneList[ml_index].amountpayable =
            (e * mgadvance) / 100;
        }
      }
    },

    checkIfMileStonesGreaterThanMg() {
      if (!this.form.milestoneList.length) return true;

      let addition = 0;
      this.form.milestoneList.forEach((ml) => {
        addition = addition + Number(ml.amountpayable);
      });

      if (addition > this.form.contract.mgadvance) {
        return false;
      }
      return true;
    },

    validationContractDetails() {
      return new Promise((resolve, reject) => {
        this.$refs.contractDetails.validate().then((success) => {
          if (success) {
            const isMileStonesGreateThanMg =
              this.checkIfMileStonesGreaterThanMg();
            if (!isMileStonesGreateThanMg) {
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title:
                    "Addition of Milestone amounts cannot be greater than MG",
                  icon: "EditIcon",
                  variant: "failure",
                },
              });
              reject();
            }

            this.computeAllRevenuShareItems();
            resolve(true);
          } else {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "Fill all mandatory fields",
                icon: "EditIcon",
                variant: "warning",
              },
            });
            reject();
          }
        });
      });
    },

    computeRevenuShareItems() {
      const arr = [];

      for (const item of this.revenueSharesAllItems) {
        const findItem = arr.find((innerItem) => {
          for (let val of this.form.trl_combination) {
            val = val.toLowerCase();
            if (item[val] !== innerItem[val]) return false;
          }
          return true;
        });

        if (!findItem) {
          Object.keys(item).map((key1) => {
            if (
              key1 == "territoryHasChild" ||
              key1 == "rightHasChild" ||
              key1 == "exclusive"
            )
              return;

            // Disscuss With Jitu about what is this
            if (item[key1] == "0") {
              for (const item1 of this.revenueSharesAllItems) {
                for (let val of this.form.trl_combination) {
                  val = val.toLowerCase();
                  if (item[val] == item1[val]) {
                    if (item1[key1] == "1") {
                      item[key1] = "1";
                    } else {
                      item[key1] = "0";
                    }
                  }
                }
              }
            }
          });
          arr.push(item);
        }
      }

      this.revenueSharesTableItems = arr;
    },

    checkIfTheatricalRight(right_id) {
      for (const right of this.$store.state.master.rights_tree) {
        if (
          right.label.toLowerCase() === "theatrical" &&
          right.id === right_id
        ) {
          return true;
        }
        if (right && right.children && right.children.length) {
          for (const child of right.children) {
            if (
              child.label.toLowerCase() === "theatrical" &&
              child.id === right_id
            ) {
              return true;
            }
          }
        }
      }
    },

    showDuplicateTrlError({ territory, right, language }) {
      const rightItem = this.getItemFromTreeData(
        right,
        this.$store.state.master.rights_tree
      );
      const territoryItem = this.getItemFromTreeData(
        territory,
        this.$store.state.master.territory_tree
      );
      const languageName = this.$store.state.master.language.find(
        (item) => language == item.lbl_id
      ).lbl_name;

      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: `Right ${rightItem.name},  Territory ${territoryItem.name}, Language ${languageName} has duplicate entry`,
          icon: "EditIcon",
          variant: "failure",
        },
      });

      return;
    },

    computeAllRevenuShareItems() {
      const rowItems = [];

      for (const trls of this.form.trls) {
        const territories = trls.territory;
        const rights = trls.right;
        const languages = trls.language;

        for (const territory of territories) {
          for (const right of rights) {
            for (const language of languages) {
              const approval = this.form.contract_approval.find((item) => {
                return (
                  item.language_id === language &&
                  (item.right_id === right || item.lbl_value1 == right) &&
                  item.territory_id === territory
                );
              });

              const exclusive =
                approval && typeof approval.exclusive === "boolean"
                  ? approval.exclusive
                  : trls.exclusive;

              let obj = {};

              // for (const rightName of this.$store.state.master.rights_tree) {
              //   if (
              //     rightName.label.toLowerCase() === "theatrical" &&
              //     rightName.id === right
              //   ) {
              //     obj = {
              //       distributor_share: approval
              //         ? approval.distributor_share
              //         : 0,
              //       exclusive: exclusive,
              //       deduction_from_rental_mg: approval
              //         ? approval.deduction_from_rental_mg
              //         : false,
              //       deduction_from_rental_pa: approval
              //         ? approval.deduction_from_rental_pa
              //         : false,

              //       distributor_commision_rental_percent: approval
              //         ? approval.distributor_commision_rental_percent
              //         : 0,
              //       distributor_commision_rental_status: approval
              //         ? approval.distributor_commision_rental_status
              //         : false,

              //       distributor_share_mg_percent: approval
              //         ? approval.distributor_share_mg_percent
              //         : 0,
              //       distributor_share_mg_status: approval
              //         ? approval.distributor_share_mg_status
              //         : false,

              //       distributor_share_status: approval
              //         ? approval.distributor_share_status
              //         : false,

              //       licensor_share_mg_percent: approval
              //         ? approval.licensor_share_mg_percent
              //         : 0,
              //       licensor_share_mg_status: approval
              //         ? approval.licensor_share_mg_status
              //         : false,

              //       licensor_share_status: approval
              //         ? approval.licensor_share_status
              //         : false,

              //       licensor_share_recopmentPa: approval
              //         ? approval.licensor_share_recopmentPa
              //         : 0,
              //     };
              //   }
              // }

              const rightItem = this.getItemFromTreeData(
                right,
                this.$store.state.master.rights_tree
              );
              const territoryItem = this.getItemFromTreeData(
                territory,
                this.$store.state.master.territory_tree
              );
              const languageName = this.$store.state.master.language.find(
                (item) => language == item.lbl_id
              ).lbl_name;

              obj = {
                ...obj,
                territory,
                territory_name: territoryItem.name,
                territoryHasChild: territoryItem.hasChild,
                right,
                right_name: rightItem.name,
                rightHasChild: rightItem.hasChild,
                language,
                language_name: languageName,
                licensor_share: approval ? approval.licensor_share : 0,
                distributor_share: approval ? approval.distributor_share : 0,
                exclusive: exclusive,

                distributor_share: approval ? approval.distributor_share : 0,
                exclusive: exclusive,
                deduction_from_rental_mg: approval
                  ? approval.deduction_from_rental_mg
                  : false,
                deduction_from_rental_pa: approval
                  ? approval.deduction_from_rental_pa
                  : false,

                distributor_commision_rental_percent: approval
                  ? approval.distributor_commision_rental_percent
                  : 0,
                distributor_commision_rental_status: approval
                  ? approval.distributor_commision_rental_status
                  : false,

                distributor_share_mg_percent: approval
                  ? approval.distributor_share_mg_percent
                  : 0,
                distributor_share_mg_status: approval
                  ? approval.distributor_share_mg_status
                  : false,

                distributor_share_status: approval
                  ? approval.distributor_share_status
                  : false,

                licensor_share_mg_percent: approval
                  ? approval.licensor_share_mg_percent
                  : 0,
                licensor_share_mg_status: approval
                  ? approval.licensor_share_mg_status
                  : false,

                licensor_share_status: approval
                  ? approval.licensor_share_status
                  : false,

                licensor_share_recopmentPa: approval
                  ? approval.licensor_share_recopmentPa
                  : 0,
              };

              rowItems.push(obj);
            }
          }
        }
      }

      this.revenueSharesAllItems = rowItems;
      this.computeRevenuShareItems();
    },

    expandRightParent(item) {
      let rightChilds = [];
      for (const right of this.$store.state.master.rights_tree) {
        if (right.id === item.right) {
          rightChilds = right.children.map((cr) => cr.id);
        }
      }

      // For RevenueShares List
      const revenueSharesAllItems = [];
      for (const shareItem of this.revenueSharesAllItems) {
        if (shareItem.right === item.right) {
          for (const rightChild of rightChilds) {
            const rightItem = this.getItemFromTreeData(
              rightChild,
              this.$store.state.master.rights_tree
            );
            revenueSharesAllItems.push({
              ...shareItem,
              right: rightChild,
              right_name: rightItem.name,
              rightHasChild: "0",
              isCollapsible: "1",
            });
          }
        } else {
          revenueSharesAllItems.push(shareItem);
        }
      }
      this.revenueSharesAllItems = revenueSharesAllItems;
      this.computeRevenuShareItems();

      // For trl on first tab
      this.form.trls.forEach((trls, index) => {
        const rights = trls.right;
        let rightsArr = [];
        for (const right of rights) {
          if (right === item.right) {
            rightsArr = rightsArr.concat(rightChilds);
          } else {
            rightsArr.push(right);
          }
        }
        // Updatin State
        trls.right = rightsArr;
      });
    },

    collapseRightParent(item) {
      let parentRightId = null;
      let rightChilds = [];

      for (const rightGroup of this.$store.state.master.rights_tree) {
        if (rightGroup.children && rightGroup.children.length) {
          for (const child of rightGroup.children) {
            if (child.id === item.right) {
              parentRightId = rightGroup.id;
              rightChilds = rightGroup.children.map((cr) => cr.id);
              break;
            }
          }
        }
      }

      const itemTerritories = [];
      const itemLanguages = [];

      // For RevenueShares List
      let parentAdded = false;
      const revenueSharesAllItems = [];
      for (const shareItem of this.revenueSharesAllItems) {
        if (
          rightChilds.includes(shareItem.right) &&
          shareItem.isCollapsible == "1"
        ) {
          if (parentAdded) continue;
          parentAdded = true;
          const rightItem = this.getItemFromTreeData(
            parentRightId,
            this.$store.state.master.rights_tree
          );

          for (const childItem of this.revenueSharesAllItems) {
            if (
              childItem.right == shareItem.right &&
              childItem.isCollapsible == "1"
            ) {
              if (!itemTerritories.includes(childItem.territory))
                itemTerritories.push(childItem.territory);
              if (!itemLanguages.includes(childItem.language))
                itemLanguages.push(childItem.language);

              revenueSharesAllItems.push({
                ...shareItem,
                right: parentRightId,
                right_name: rightItem.name,
                rightHasChild: "1",
                isCollapsible: "0",
              });
            }
          }
        } else {
          revenueSharesAllItems.push(shareItem);
        }
      }
      this.revenueSharesAllItems = revenueSharesAllItems;
      this.computeRevenuShareItems();

      // For trl on first tab
      this.form.trls.forEach((trls) => {
        const territories = trls.territory;
        const rights = trls.right;
        const languages = trls.language;

        let allTerritoriesIncluded = true;
        let allLanguagesIncluded = true;

        itemTerritories.forEach((t) => {
          if (!territories.includes(t)) allTerritoriesIncluded = false;
        });

        itemLanguages.forEach((l) => {
          if (!languages.includes(l)) allLanguagesIncluded = false;
        });

        if (!allLanguagesIncluded || !allTerritoriesIncluded) return;

        let rightsArr = [];
        let parentIdAdded = false;
        for (const right of rights) {
          if (rightChilds.includes(right)) {
            if (!parentIdAdded) {
              parentIdAdded = true;
              rightsArr.push(parentRightId);
            }
          } else {
            rightsArr.push(right);
          }
        }
        // Updatin State
        trls.right = rightsArr;
      });
    },

    // Revenue Share Table Functions
    getItemFromTreeData(id, tree_data) {
      for (const item of tree_data) {
        if (item.id === id) {
          return {
            name: item.label,
            hasChild: item.children && item.children.length ? "1" : "0",
          };
        }
        if (item.children && item.children.length) {
          for (const child of item.children) {
            if (child.id === id) {
              return {
                name: `${child.label} (${item.label})`,
                hasChild: "0",
              };
            }
          }
        }
      }
      return {};
    },

    checkIfSameGroup(id, item_id, group_key) {
      if (id === item_id) return true;

      let treeData;
      if (group_key === "Right") {
        treeData = this.$store.state.master.rights_tree;
      } else if (group_key === "Territory") {
        treeData = this.$store.state.master.territory_tree;
      }

      let group;
      for (const item of treeData) {
        if (item.id == id) group = item;
        for (const child of item.children) {
          if (child.id == id) group = item;
        }
      }

      if (item_id == group.id) return true;
      for (const child of group.children) {
        if (child.id == item_id) return true;
      }
      return false;
    },

    setRevenuShareItemValue(key, value, row) {
      const list = JSON.parse(JSON.stringify(this.revenueSharesAllItems));

      for (const item of list) {
        let checkEquivalent = true;
        for (let val of this.form.trl_combination) {
          val = val.toLowerCase();
          if (item[val] !== row[val]) checkEquivalent = false;
        }
        if (checkEquivalent) item[key] = value;
      }

      if (this.revenuShareGroupValue) {
        for (const item of list) {
          const k = this.revenuShareGroupValue.toLowerCase();
          const checkSameGroup = this.checkIfSameGroup(
            row[k],
            item[k],
            this.revenuShareGroupValue
          );
          if (checkSameGroup) item[key] = value;
        }
      }

      this.revenueSharesAllItems = list;
      if (
        key == "distributor_share_status" ||
        key == "distributor_share_mg_status" ||
        key == "distributor_commision_rental_status"
      ) {
        let checkForMGPA = this.revenueSharesAllItems.filter((x) => {
          return (
            (x.distributor_share_status == 1 &&
              x.distributor_share_mg_status == 1) ||
            x.distributor_commision_rental_status == 1
          );
        });

        if (checkForMGPA.length > 0) {
          this.showMGPAWarning();
        }
      }

      this.computeRevenuShareItems();
    },

    showMGPAWarning() {
      const h = this.$createElement;

      const messageVNode = h("div", [
        h("p", [
          "1- DISTRIBUTOR CORRIDOR PRIOR TO RECOUPEMENT OF P&A and DISTRIBUTOR CORRIDOR PRIOR TO RECOUPEMENT OF MG cannot be yes together.",
        ]),
        h("p", [
          "2- If DISTRIBUTOR CORRIDOR PRIOR TO RECOUPEMENT OF P&A AND MG is yes then point 1 parameters will be no",
        ]),
      ]);
      this.$bvModal
        .msgBoxOk([messageVNode], {
          title: "Warning",
          size: "sm",
          okVariant: "primary",
          okTitle: "OK",
          cancelDisabled: "true",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.UpdateMGPA();
          }
        });
    },

    UpdateMGPA() {
      let checkForMGPA = this.revenueSharesAllItems.filter((x) => {
        return (
          x.distributor_share_status == 1 && x.distributor_share_mg_status == 1
        );
      });

      if (checkForMGPA.length > 0) {
        for (let item of checkForMGPA) {
          item.distributor_share_status = 0;
          item.distributor_share = 0;
          item.distributor_share_mg_status = 0;
          item.distributor_share_mg_percent = 0;

          item.distributor_commision_rental_status = 1;
        }
      } else {
        let checkForMGPANew = this.revenueSharesAllItems.filter((x) => {
          return (
            x.distributor_share_status == 1 ||
            x.distributor_share_mg_status == 1
          );
        });

        if (checkForMGPANew.length > 0) {
          for (let item of checkForMGPANew) {
            item.distributor_commision_rental_status = 0;
            item.distributor_commision_rental_percent = 0;
          }
        }
      }
    },

    async getContractDetails(contract_id) {
      try {
        const response = await ContractServices.getSingleContract(contract_id);
        if (response.data.status) {
          const form = reduceContractFormData(response.data.data);
          if (form.milestoneList && form.milestoneList.length) {
            this.selectedCollaps.push("Milestones");
          }
          if (form.bumpers && form.bumpers.length > 0) {
            this.selectedCollaps.push("Bumpers");
          }
          if (form.contract_statement && form.contract_statement.length > 0) {
            this.selectedCollaps.push("Statement_Period");
          }
          if (form.contract_access && form.contract_access.length > 0) {
            this.selectedCollaps.push("Language_Access");
          }
          if (form.contract_products && form.contract_products.length > 0) {
            this.selectedCollaps.push("Finished_Product");
          }
          if (form.hold_back && form.hold_back.length > 0) {
            this.selectedCollaps.push("Holdback");
          }
          if (form.support_item && form.support_item.length > 0) {
            this.selectedCollaps.push("Support");
          }
          if (form.contract.assets && form.contract.assets.length > 0) {
            this.selectedCollaps.push("Assets");
          }
          if (form.contract_products && form.contract_products.length > 0)
            this.form_dynamics.is_finished_product = true;
          this.form = {
            ...this.form,
            ...form,
          };

          const trls_options = [
            // {
            //   trl_languages: [],
            //   trl_rights: [],
            //   trl_territories: [],
            // },
          ];
          for (const trl of this.form.trls) {
            trls_options.push({
              trl_languages: this.$store.state.master.language,
              trl_rights: this.$store.state.master.rights_tree,
              trl_territories: this.$store.state.master.territory_tree,
            });
          }
          this.trls_options = trls_options;
        }
      } catch (err) {
        console.error("Error in getting  ", err);
      }
    },

    checkRevenueShareValidity() {
      let checkRevenueShareValidity = true;
      for (const item of this.revenueSharesAllItems) {
        if (
          (item.distributor_commision_rental_percent &&
            item.distributor_commision_rental_percent < 0) ||
          item.distributor_commision_rental_percent > 100
        ) {
          checkRevenueShareValidity = false;
          break;
        }
        if (
          (item.distributor_share && item.distributor_share < 0) ||
          item.distributor_share > 100
        ) {
          checkRevenueShareValidity = false;
          break;
        }
        if (
          (item.distributor_share_mg_percent &&
            item.distributor_share_mg_percent < 0) ||
          item.distributor_share_mg_percent > 100
        ) {
          checkRevenueShareValidity = false;
          break;
        }
        if (
          (item.licensor_share && item.licensor_share < 0) ||
          item.licensor_share > 100
        ) {
          checkRevenueShareValidity = false;
          break;
        }
        if (
          (item.licensor_share_mg_percent &&
            item.licensor_share_mg_percent < 0) ||
          item.licensor_share_mg_percent > 100
        ) {
          checkRevenueShareValidity = false;
          break;
        }
        if (
          (item.licensor_share_recopmentPa &&
            item.licensor_share_recopmentPa < 0) ||
          item.licensor_share_recopmentPa > 100
        ) {
          checkRevenueShareValidity = false;
          break;
        }
      }

      return checkRevenueShareValidity;
    },

    formSubmitted() {
      const checkRevenueShareValidity = this.checkRevenueShareValidity();

      if (!checkRevenueShareValidity) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Shares Amount Should be in percent",
            icon: "EditIcon",
            variant: "failure",
          },
        });
        return;
      }

      this.saveContract();
    },

    saveContractAsDraft() {
      this.form.draft = true;
      this.$nextTick(() => {
        this.formSubmitted();
      });
    },

    async saveContract() {
      try {
        let payload = {
          ...this.form,
          contract_approval: this.revenueSharesAllItems,
        };

        if (!this.is_copy && this.contract_id) {
          payload = {
            ...payload,
            contract_id: this.contract_id,
          };
        }

        const response = await ContractServices.saveContract(payload);
        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Contract Saved",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.$router.go(-1);
        } else {
          this.validationError.message = response.data.message;
          this.validationError.data = response.data.data;
          this.$refs["validationErrorModal"].show();
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Contract Not Saved",
              icon: "EditIcon",
              variant: "warning",
            },
          });
        }
      } catch (err) {
        console.error("Error in getting  ", err);
      }
    },

    nextTickSync() {
      return new Promise((resolve) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },
  },

  async beforeMount() {
    this.$store.dispatch("master/setVendorsMaster");
    this.$store.dispatch("master/setMoviesMaster");

    this.$store.dispatch("master/setLabelMaster", {
      key: labels.contract_type,
    });
    this.$store.dispatch("master/setLabelMaster", {
      key: labels.revenue_share,
    });
    this.$store.dispatch("master/setLabelMaster", { key: labels.currency });
    this.$store.dispatch("master/setLabelMaster", { key: labels.milestone });
    this.$store.dispatch("master/setLabelMaster", { key: labels.bumper });

    await this.$store.dispatch("master/setLabelMaster", {
      key: labels.language,
    });

    await this.$store.dispatch("master/setTerritoryMaster");

    await this.$store.dispatch("master/setRightMaster");

    this.trl_languages = this.$store.state.master.language;
    this.trl_rights = this.$store.state.master.rights_tree;
    this.trl_territories = this.$store.state.master.territory_tree;

    this.trls_options.push({
      trl_languages: this.$store.state.master.language,
      trl_rights: this.$store.state.master.rights_tree,
      trl_territories: this.$store.state.master.territory_tree,
    });

    const contract_id = this.$route.params.contract_id;
    if (contract_id && !isNaN(contract_id)) {
      this.getContractDetails(contract_id);
      this.contract_id = contract_id;

      const urlParams = new URLSearchParams(window.location.search);
      const is_copy = urlParams.get("copy");

      if (is_copy && is_copy == "1") {
        this.is_copy = true;
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#contractTablesStyle {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#contractTablesStyle td,
#contractTablesStyle th {
  border: 1px solid #ddd;
  padding: 5px;
}

#contractTablesStyle tr {
  background-color: #fff;
}

#contractTablesStyle th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #e4e6ef;
  color: #1f307a;
}

hr.dotted {
  border-top: 2px dotted #999;
  width: 96%;
}

.profile-form-wizard .wizard-nav {
  justify-content: center !important;
}

.profile-form-wizard .form-card {
  border: 2px solid #d8d6de;
  border-radius: 05px;
  padding: 20px;
}

.profile-form-wizard .form-card h4 {
  color: #000;
  font-weight: bold;
  text-transform: uppercase;
}

.profile-form-wizard .form-card .form-group label {
  font-size: 13px !important;
  color: #000 !important;
}

.profile-form-wizard .form-card .form-group legend {
  font-size: 13px !important;
  color: #000 !important;
}

.save-as-draft-btn {
  float: right;
  width: 170px !important;
}

.bg-color {
  // background-color: rgba(60, 60, 155, 0.1);
}
</style>
